import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas'; // Import html2canvas
import './UserDash.css'; // Import custom CSS
import Header from '../componets/Header';
import { Link } from 'react-router-dom';
import { BASE_SERVER_URL } from "../../utilis/EndPoints";

const UserDash = () => {
  const [candidate, setCandidate] = useState(null);

  useEffect(() => {
    const fetchCandidateData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${BASE_SERVER_URL}/candidate`, {
          headers: {
            token: `${token}`
          }
        });
        setCandidate(response.data.result);
      } catch (error) {
        console.error('Error fetching candidate data:', error);
      }
    };

    fetchCandidateData();
  }, []);

  const downloadPDF = async () => {
    const input = document.getElementById('contentToPrint'); // Use the ID of the content to capture
    if (input) {
      try {
        const canvas = await html2canvas(input);
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 210; // A4 width in mm
        const pageHeight = 295; // A4 height in mm
        const imgHeight = canvas.height * imgWidth / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save('candidate_info.pdf');
      } catch (error) {
        console.error('Error generating PDF:', error);
      }
    }
  };

  return (
    <>
      <Header />
      <div className="container user-dash-container" id="contentToPrint"> {/* Add the ID here */}
        <p><b><i>Note:</i></b> <Link to='/information'>कंपनियों द्वारा दिए गए परिणामों को जानने के लिए इस लिंक पर क्लिक करें।</Link></p>
        {candidate ? (
          <div className="card candidate-card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-4 doted">
                  <div className="card candidate-info-card">
                    <div className="card-header">उम्मीदवार की जानकारी</div>
                    <div className="card-body">
                      <h3 className="candidate-name">{candidate.name}</h3>
                      <h5 className="candidate-email text-dark fw-bold">पंजीकरण आईडी: {candidate.registrationId}</h5>
                      <ul className="candidate-details">
                        <li className='mb-2'><strong>पिता का नाम:</strong> {candidate.fatherName}</li>
                        <li className='mb-2'><strong>जन्म तिथि:</strong> {new Date(candidate.dob).toLocaleDateString()}</li>
                        <li className='mb-2'><strong>लिंग:</strong> {candidate.gender}</li>
                        <li className='mb-2'><strong>फोन:</strong> {candidate.phone}</li>
                        <li className='mb-2'><strong>योग्यता:</strong> {candidate.qualification.join(', ')}</li>
                        <li className='mb-2'><strong>रुचि:</strong> {candidate.interest}</li>
                      </ul>
                      <button onClick={downloadPDF} className="btn btn-sm btn-success">पीडीएफ डाउनलोड करें</button>
                    </div>
                  </div>
                </div>
                <div className="col-8 card2">
                  <div className="companies-card">
                    <div className="card-header">कंपनियाँ</div>
                    <div className="card-body">
                      {candidate.companies.length > 0 ? (
                        <div className="table-responsive">
                          <table className="table table-striped table-hover company-table">
                            <thead>
                              <tr>
                                <th>क्र. कंपनी का नाम</th>
                                <th>कक्ष नंबर</th>
                              </tr>
                            </thead>
                            <tbody>
                              {candidate.companies.map((company) => (
                                <tr key={company._id}>
                                  <td>{company.companyId.companyName}</td>
                                  <td>{company.companyId.roomNo}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <p>कोई कंपनियाँ उपलब्ध नहीं हैं।</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="loading">उम्मीदवार की जानकारी लोड हो रही है...</div>
        )}
      </div>
    </>
  );
};

export default UserDash;
