import React, { useState, useEffect } from "react";
import "../css/Slider.css";
import { AiOutlineLogout } from "react-icons/ai";
import {
  BiBell,
  BiHomeAlt,
  BiGridAlt,
  BiLoaderCircle,
  BiFilter,
  BiMenu,
  BiChevronRight,
  BiUser,
} from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";

const Slider = ({onToggleSidebar}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [senderId, setSendId] = useState("");
  const userId = localStorage.getItem("userId");
  const role = localStorage.getItem("role");
  const navigate = useNavigate();

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsSidebarOpen(false);
    }
  }, []);
  useEffect(() => {
    onToggleSidebar(isSidebarOpen);
  }, [isSidebarOpen, onToggleSidebar]);

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };









  const handleLogout = () => {
    swal({
      title: "Are you sure?",
      text: "Are you sure that you want to logout?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willLogout) => {
      if (willLogout) {
        swal("Logout!", "You are logged out successfully", "success");
        localStorage.removeItem("authToken");
        localStorage.removeItem("userId");
        localStorage.removeItem("user");
        localStorage.removeItem("role");
        navigate("/");
      }
    });
  };



  return (
    <>
      <div className={isDarkMode ? "dark" : ""}>
        <nav className="navbar">
          <div className="logo_item">
            <BiMenu id="sidebarOpen" onClick={handleSidebarToggle} />
            रोजगार मेला          </div>

          <div className="navbar_content">
            
            <Link to="/ProfilePage">
              <BiUser />
            </Link>
            <AiOutlineLogout onClick={handleLogout} style={{ cursor: "pointer" }} />
          </div>
        </nav>

        <nav className={`sidebar ${isSidebarOpen ? "" : "close"}`}>
          <div className="menu_content">
            <ul className="menu_items">
                <>
                  <Link to="/">
                    <li className="item">
                      <div className="nav_link submenu_item">
                        <span className="navlink_icon">
                          <BiHomeAlt />
                        </span>
                        <span className="navlink">Home</span>
                        <BiChevronRight className="arrow-left" />
                      </div>
                    </li>
                  </Link>
              
               
                </>
              
              
            </ul>

            <div className="bottom_content">
              <div
                className="bottom collapse_sidebar"
                onClick={handleSidebarToggle}
              >
                <span>रोजगार मेला</span>
                <BiMenu />

              </div>
            </div>
          </div>
        </nav>
      </div>
      
    </>
  );
};

export default Slider;
