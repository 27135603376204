import React, { useState, useEffect } from "react";
import "../../css/Slider.css";
import { AiOutlineLogout } from "react-icons/ai";
import {
    BiHomeAlt,
    BiBarChart,
    BiMenu,
    BiChevronRight,
    BiUser,
    BiListUl,
    BiBuilding,
} from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import Navbar from "../componets/Navbar";

const Sidebar = ({ isSidebarOpen, setIsSidebarOpen }) => {
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [activeSubmenu, setActiveSubmenu] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [senderId, setSendId] = useState("");
    const userId = localStorage.getItem("userId");
    const role = localStorage.getItem("role");
    const navigate = useNavigate();

    useEffect(() => {
        if (window.innerWidth < 768) {
            setIsSidebarOpen(false);
        }
    }, []);



    const handleSidebarToggle = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };



    return (
        <>
            <Navbar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
            <div className={"dark"}>
                {/* <nav className="navbar">
                    <div className="logo_item">
                        <BiMenu id="sidebarOpen" onClick={handleSidebarToggle} />
                        रोजगार मेला
                    </div>
                    <div className="navbar_content">
                        <Link to="/ProfilePage">
                            <BiUser />
                        </Link>
                        <AiOutlineLogout onClick={handleLogout} style={{ cursor: "pointer" }} />
                    </div>
                </nav> */}

                <nav className={`sidebar ${isSidebarOpen ? "" : "close"}`}>
                    <div className="menu_content">
                        <ul className="menu_items text-dark">
                            <li className="item">
                                <Link to="/admindash" className="nav_link d-flex align-items-center">
                                    <span className="navlink_icon">
                                        <BiHomeAlt />
                                    </span>
                                    <span className="navlink">डैशबोर्ड</span>
                                    <BiChevronRight className="arrow-left" />
                                </Link>
                            </li>
                            <li className="item">
                                <Link to="/companies" className="nav_link d-flex align-items-center">
                                    <span className="navlink_icon">
                                        <BiListUl />
                                    </span>
                                    <span className="navlink">कंपनियाँ</span>
                                    <BiChevronRight className="arrow-left" />
                                </Link>
                            </li>
                            <li className="item">
                                <Link to="/candidates" className="nav_link d-flex align-items-center">
                                    <span className="navlink_icon">
                                        <BiUser />
                                    </span>
                                    <span className="navlink">उम्मीदवार</span>
                                    <BiChevronRight className="arrow-left" />
                                </Link>
                            </li>
                            <li className="item">
                                <Link to="/allotno" className="nav_link d-flex align-items-center">
                                    <span className="navlink_icon">
                                        <BiBuilding />
                                    </span>
                                    <span className="navlink">कक्ष आवंटित करें</span>
                                    <BiChevronRight className="arrow-left" />
                                </Link>
                            </li>
                            <li className="item">
                                <Link to="/results" className="nav_link d-flex align-items-center">
                                    <span className="navlink_icon">
                                        <BiBarChart />
                                    </span>
                                    <span className="navlink">उम्मीदवार परिणाम</span>
                                    <BiChevronRight className="arrow-left" />
                                </Link>
                            </li>
                            <li className="item">
                                <Link to="/results2" className="nav_link d-flex align-items-center">
                                    <span className="navlink_icon">
                                        <BiBarChart />
                                    </span>
                                    <span className="navlink">कंपनियाँ परिणाम</span>
                                    <BiChevronRight className="arrow-left" />
                                </Link>
                            </li>
                        </ul>

                        <div className="bottom_content">
                            <div className="bottom collapse_sidebar" onClick={handleSidebarToggle}>
                                <span>रोजगार मेला</span>
                                <BiMenu />
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    );
};

export default Sidebar;
