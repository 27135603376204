import React, { useEffect } from 'react';
import { AiOutlineLogout } from 'react-icons/ai';
import { BiMenu } from 'react-icons/bi';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const Navbar = ({ isSidebarOpen, setIsSidebarOpen }) => {
    const navigate = useNavigate();

    const handleLogout = async () => {
        const result = await Swal.fire({
            title: 'क्या आप सुनिश्चित हैं?',
            text: "क्या आप लॉग आउट करना चाहते हैं?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'हाँ, लॉग आउट करें',
            cancelButtonText: 'नहीं, रद्द करें'
        });
        if (result.isConfirmed) {
            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            localStorage.removeItem('user');
            localStorage.removeItem('role');
            localStorage.removeItem('companyID');
            navigate('/');
        }
    };

    useEffect(() => {
        // Hide sidebar on mobile view when the component mounts
        if (window.innerWidth < 768) {
            setIsSidebarOpen(false);
        }
        // Add event listener for resizing
        const handleResize = () => {
            if (window.innerWidth >= 768) {
                setIsSidebarOpen(true);
            } else {
                setIsSidebarOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [setIsSidebarOpen]);

    const handleSidebarToggle = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" onClick={handleSidebarToggle}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <a className="navbar-brand ml-4" href="#" >रोजगार मेला</a>
                <div className="d-flex align-items-center mr-3">
                    <AiOutlineLogout style={{ cursor: "pointer" }} onClick={handleLogout} />
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
