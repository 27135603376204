import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from './Sidebar';
import { BASE_SERVER_URL } from "../../utilis/EndPoints";
import * as XLSX from 'xlsx';
import ReactPaginate from 'react-paginate';
// Ensure this CSS file contains the necessary styles for pagination

const Results = () => {
    const [candidates, setCandidates] = useState([]);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [itemsPerPage] = useState(10);

    useEffect(() => {
        const fetchCandidates = async () => {
            try {
                const token = localStorage.getItem("token");
                const response = await axios.get(`${BASE_SERVER_URL}/allcandidate`, {
                    headers: { token: `${token}` },
                    params: { page: currentPage + 1 } // Adjusted for 1-based index
                });
                setCandidates(response.data.result);
                setTotalPages(response.data.totalPages); // Make sure your API returns totalPages
            } catch (error) {
                console.error("Error fetching candidates:", error);
            }
        };

        fetchCandidates();
    }, [currentPage]);

    const handleSidebarToggle = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const extractDataForDownload = () => {
        let data = [];
        candidates.forEach((candidate, index) => {
            if (candidate.companies && candidate.companies.length > 0) {
                candidate.companies.forEach(company => {
                    data.push({
                        क्रं: index + 1 + currentPage * itemsPerPage,
                        नाम: candidate.name,
                        पंजीकरण_संख्या: candidate.registrationId,
                        कंपनी: company.companyId.companyName,
                        उपस्थिति: company.present ? 'उपस्थित' : 'अनुपस्थित',
                        परिणाम: company.result === 'selected' ? 'चयनित' : company.result === 'rejected' ? 'अस्वीकृत' : company.result || 'N/A',
                        टिप्पणी: company.remark,
                    });
                });
            } else {
                data.push({
                    क्रं: index + 1 + currentPage * itemsPerPage,
                    नाम: candidate.name,
                    पंजीकरण_संख्या: candidate.registrationId,
                    कंपनी: 'कोई रिकॉर्ड नहीं मिला',
                    उपस्थिति: '',
                    परिणाम: '',
                    टिप्पणी: '',
                });
            }
        });
        return data;
    };

    const downloadExcel = () => {
        const data = extractDataForDownload();
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "candidates");
        XLSX.writeFile(workbook, "result_data.xlsx");
    };

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    return (
        <div className="container-fluid">
            <div className="row">
                {/* Sidebar */}
                <Sidebar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />

                {/* Main Content */}
                <main className={`content ${isSidebarOpen ? "col-md-10" : "col-md-11"} p-4 ml-auto`} style={{ transition: "margin-left 0.3s" }}>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <h1 className="mb-4">परिणाम</h1>
                        <button className="btn btn-sm btn-success fw-bold" onClick={downloadExcel}>एक्सेल डाउनलोड करें</button>
                    </div>

                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>क्रं.</th>
                                <th>नाम</th>
                                <th>पंजीकरण संख्या</th>
                                <th>कंपनियाँ</th>
                                <th>उपस्थिति</th>
                                <th>परिणाम</th>
                                <th>टिप्पणी</th>
                            </tr>
                        </thead>
                        <tbody>
                            {candidates.length > 0 ? (
                                candidates.map((candidate, index) => (
                                    <tr key={candidate._id}>
                                        <td data-label="क्रं.">{index + 1 + currentPage * itemsPerPage}</td>
                                        <td data-label="नाम">{candidate.name}</td>
                                        <td data-label="पंजीकरण संख्या">{candidate.registrationId}</td>
                                        <td colSpan="4" data-label="स्थान">
                                            {candidate.companies && candidate.companies.length > 0 ? (
                                                <table className="table table-bordered">
                                                    <tbody>
                                                        {candidate.companies.map((company) => (
                                                            <tr key={company._id}>
                                                                <td className='w-25' data-label="कंपनियाँ" >{company.companyId.companyName}</td>
                                                                <td data-label="उपस्थिति" className={`fw-bold w-25 ${company.present ? 'text-success' : 'text-danger'}`}>
                                                                    {company.present ? 'उपस्थित' : 'अनुपस्थित'}
                                                                </td>
                                                                <td data-label="परिणाम" style={{ color: company.result === 'selected' ? 'green' : company.result === 'rejected' ? 'red' : 'black', fontWeight: '500' }} className='w-25' >
                                                                    {company.result === 'selected' ? 'चयनित' : company.result === 'rejected' ? 'अस्वीकृत' : company.result === 'pending' ? 'लंबित' : company.result || 'N/A'}
                                                                </td>
                                                                <td data-label="टिप्पणी" className='w-25' >{company.remark}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            ) : 'कोई रिकॉर्ड नहीं मिला'}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="7" className="text-center">कोई रिकॉर्ड नहीं मिला</td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                    {/* Pagination Controls */}
                    <div className="d-flex justify-content-center mt-4">
                        <ReactPaginate
                            previousLabel={'< Previous'}
                            nextLabel={'Next >'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={totalPages}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            breakLinkClassName={'page-link'}
                        />
                    </div>
                </main>
            </div>
        </div>
    );
};

export default Results;