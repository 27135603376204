import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from './Sidebar';
import { BASE_SERVER_URL } from "../../utilis/EndPoints";

const AdminDashboard = () => {
    const [companiesCount, setCompaniesCount] = useState(0);
    const [candidatesCount, setCandidatesCount] = useState(0);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    useEffect(() => {
        // कंपनियों और उम्मीदवारों की कुल संख्या प्राप्त करें
        const fetchData = async () => {
            try {
                const token = localStorage.getItem("token");

                // कंपनियों के डेटा को हैडर के साथ प्राप्त करें
                const companiesResponse = await axios.get(`${BASE_SERVER_URL}/fetchCompanies`, {
                    headers: {
                        token: `${token}`
                    }
                });

                // उम्मीदवारों के डेटा को हैडर के साथ प्राप्त करें
                const candidatesResponse = await axios.get(`${BASE_SERVER_URL}/allcandidate`, {
                    headers: {
                        token: `${token}`
                    }
                });

                console.log("कंपनियाँ", companiesResponse.data.result);
                console.log("उम्मीदवार", candidatesResponse.data);
                const nonAdminCandidates = candidatesResponse.data;
                const nonAdmincompanies = companiesResponse.data.result.filter(candidate => candidate.role !== 'admin');

                setCompaniesCount(nonAdmincompanies.length);
                setCandidatesCount(nonAdminCandidates.totalResults);
            } catch (error) {
                console.error("डेटा प्राप्त करने में त्रुटि:", error);
            }
        };

        fetchData();
    }, []);

    const handleSidebarToggle = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className="container-fluid">
            <div className="row">
                {/* साइडबार */}
                <Sidebar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />

                {/* मुख्य सामग्री */}
                <main className={`content ${isSidebarOpen ? "col-md-10" : "col-md-11 "} p-4 ml-auto mt-5`} style={{ transition: "margin-left 0.3s" }}>
                    <div className="d-flex flex-wrap justify-content-around  gap-5" style={{ flexWrap: 'wrap' }}>
                        <div className="stat-item bg-primary text-white shadow-sm rounded p-4 text-center flex-fill mb-3 mb-md-0 border-color-black">
                            <h2>कुल कंपनियाँ</h2>
                            <p className="fs-4">{companiesCount || 0}</p>
                        </div>
                        <div className="stat-item bg-primary text-white shadow-sm rounded p-4 text-center flex-fill">
                            <h2>कुल उम्मीदवार</h2>
                            <p className="fs-4">{candidatesCount || 0}</p>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default AdminDashboard;