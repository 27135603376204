import React from 'react'
import "./App.css";
import Dashboard from './pages/Dashboard'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import Login from './pages/Login'
import UserDash from './pages/user/UserDash'
import AdminDashboard from './pages/admin/AdminDashboard'
import Companies from './pages/admin/Companies'
import Candidates from './pages/admin/Candidates'
import CompanyLogin from './pages/company/CompanyLogin'
import CompanyDash from './pages/company/CompanyDash'
import AllotNo from './pages/admin/AllotNo'
import CompanyResult from './pages/company/CompanyResult'
import Results from './pages/admin/Result';
import Results2 from './pages/admin/Results2';
import Information from './pages/user/Infomation';

const App = () => {
  return (
    <>
      <BrowserRouter >
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/dash' element={<Dashboard />} />
          <Route path='/login' element={<Login />} />
          <Route path='/companylogin' element={<CompanyLogin />} />
          <Route path='/companydash' element={<CompanyDash />} />
          <Route path='/companydash2' element={<CompanyResult />} />

          <Route path='/userdash' element={<UserDash />} />
          <Route path='/admindash' element={<AdminDashboard />} />
          <Route path='/companies' element={<Companies />} />
          <Route path='/candidates' element={<Candidates />} />
          <Route path='/results' element={<Results />} />
          <Route path='/results2' element={<Results2 />} />
          <Route path='/allotno' element={<AllotNo />} />
          <Route path='/information' element={<Information />} />

        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App