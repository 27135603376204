import { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "./componets/Header"; // Corrected the path
import { BASE_SERVER_URL } from "../utilis/EndPoints";
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";
const Login = () => {
  const navigate = useNavigate();
  const [loginId, setLoginId] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${BASE_SERVER_URL}/candidatelogin`, {
        phone: loginId,
        registrationId: loginPassword,
      });

      console.log(response.data);

      Swal.fire("सफलता", "लॉगिन सफल रहा!", "success");
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("userid", response.data.result._id);
      setIsLoggedIn(true);

      navigate("/userdash");
    } catch (error) {
      if (error.response) {
        setErrorMessage(error.response.data.message || "Login failed. Please try again.");
        Swal.fire("Error", error.response.data.error || error.response.data.message, "error");
      } else {
        setErrorMessage("Login failed. Please try again.");
        Swal.fire("Error", "Login failed. Please try again.", "error");
      }
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("token");
    Swal.fire("Success", "Logged out successfully!", "success");
    navigate("/");
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <>
      <Header />
      <div className="container mt-5">
        {isLoggedIn ? (
          <div className="text-center">
            <h2>You are logged in!</h2>
            <button className="btn btn-primary mt-3" onClick={handleLogout}>
              Logout
            </button>
          </div>
        ) : (
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="card p-4">
                <h2 className="card-title text-center mb-4">उम्मीदवार लॉगिन</h2>
                <form onSubmit={handleLogin}>
                  <div className="mb-3">
                    <label className="form-label">नंबर/Number:</label>
                    <input
                      type="number"
                      className="form-control"
                      value={loginId}
                      onChange={(e) => setLoginId(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3 position-relative">
                    <label className="form-label">पंजीकरण संख्या/RegistrationID:</label>
                    <input
                      type={passwordVisible ? "text" : "password"}
                      className="form-control"
                      value={loginPassword}
                      onChange={(e) => setLoginPassword(e.target.value)}
                      required
                    />
                    <button
                      type="button"
                      className="btn  position-absolute top-50 end-0 border-none "
                      style={{ right: "10px", marginTop: "-3px" }}
                      onClick={togglePasswordVisibility}
                    >
                      {passwordVisible ? <FaRegEye /> : <FaRegEyeSlash />}
                    </button>
                  </div>
                  {errorMessage && (
                    <div className="alert alert-danger" role="alert">
                      {errorMessage}
                    </div>
                  )}
                  <div className="d-grid">
                    <button type="submit" className="btn btn-primary">
                      लॉगिन
                    </button>
                  </div>
                </form>
                <div className="text-center mt-3">
                  <Link to="/companylogin">क्या आप एक कंपनी हैं? कृपया यहाँ लॉगिन करें।</Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Login;
