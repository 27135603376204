import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from './Sidebar';
import { BASE_SERVER_URL } from "../../utilis/EndPoints";
import * as XLSX from 'xlsx';
import ReactPaginate from 'react-paginate'; // Import pagination library

const Results2 = () => {
    const [companies, setCompanies] = useState([]);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage] = useState(10); // Number of items per page

    useEffect(() => {
        // Fetch the list of companies with candidates' details
        const token = localStorage.getItem("token");
        const fetchCompaniesWithCandidates = async () => {
            try {
                const response = await axios.get(`${BASE_SERVER_URL}/getAllCompaniesWithCandidates`,
                    {
                        headers: { token: `${token}` }
                    });
                setCompanies(response.data); // Assuming the API returns an array of companies
                console.log("Data", response.data);
            } catch (error) {
                console.error("Error fetching companies with candidates:", error);
            }
        };

        fetchCompaniesWithCandidates();
    }, []);

    const handleSidebarToggle = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const extractDataForDownload = () => {
        let data = companies.map((company, index) => ({
            क्रं: index + 1,
            कंपनी: company.company.companyName,
            कुल_उपस्थित_उम्मीदवार: company.presentCandidates,
            चयनित_उम्मीदवार: company.selectedCount,
            अस्वीकृत_उम्मीदवार: company.rejectedCount
        }));
        return data;
    };

    const downloadExcel = () => {
        const data = extractDataForDownload();
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "companies");
        XLSX.writeFile(workbook, "companies_data.xlsx");
    };

    // Pagination logic
    const indexOfLastCompany = (currentPage + 1) * itemsPerPage;
    const indexOfFirstCompany = indexOfLastCompany - itemsPerPage;
    const currentCompanies = companies.slice(indexOfFirstCompany, indexOfLastCompany);

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    return (
        <div className="container-fluid">
            <div className="row">
                {/* Sidebar */}
                <Sidebar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />

                {/* Main Content */}
                <main className={`content ${isSidebarOpen ? "col-md-10" : "col-md-11"} p-4 ml-auto`} style={{ transition: "margin-left 0.3s" }}>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <h1 className="mb-4">परिणाम - कंपनियाँ</h1>
                        <button className="btn btn-sm btn-success fw-bold" onClick={downloadExcel}>एक्सेल डाउनलोड करें</button>
                    </div>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>क्रं. कंपनी का नाम</th>
                                <th>कुल उपस्थित उम्मीदवार</th>
                                <th>चयनित उम्मीदवार</th>
                                <th>अस्वीकृत उम्मीदवार</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentCompanies.length > 0 ? (
                                currentCompanies.map((company, index) => (
                                    <tr key={company.company._id}>
                                        <td data-label="कंपनी">{company.company.companyName}</td>
                                        <td data-label="कुल उपस्थित उम्मीदवार">{company.presentCandidates}</td>
                                        <td data-label="चयनित उम्मीदवार">{company.selectedCount}</td>
                                        <td data-label="अस्वीकृत उम्मीदवार">{company.rejectedCount}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5" className="text-center">कोई रिकॉर्ड नहीं मिला</td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                    {/* Pagination Component */}
                    <div className="d-flex justify-content-center mt-4">
                        <ReactPaginate
                            previousLabel={'< Previous'}
                            nextLabel={'Next >'}
                            breakLabel={'...'}
                            pageCount={Math.ceil(companies.length / itemsPerPage)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={'pagination'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            breakClassName={'page-item'}
                            breakLinkClassName={'page-link'}
                            activeClassName={'active'}
                        />
                    </div>
                </main>
            </div>
        </div>
    );
};

export default Results2;
