import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from './Sidebar';
import { BASE_SERVER_URL } from "../../utilis/EndPoints";

const AllotNo = () => {
    const [companies, setCompanies] = useState([]);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [roomNumbers, setRoomNumbers] = useState({});
    const [token, setToken] = useState("");

    useEffect(() => {
        // Fetch the list of companies
        const token = localStorage.getItem("token");
        setToken(token);

        const fetchCompanies = async () => {
            try {
                const response = await axios.get(`${BASE_SERVER_URL}/fetchCompanies`);
                setCompanies(response.data.result.filter(candidate => candidate.role !== 'admin')); // Adjust based on actual API response
            } catch (error) {
                console.error("Error fetching companies:", error);
            }
        };

        fetchCompanies();
    }, []);

    const handleSidebarToggle = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleRoomNumberChange = (companyId, value) => {
        setRoomNumbers({
            ...roomNumbers,
            [companyId]: value,
        });
    };

    const allotRoomNumber = async (companyId) => {
        console.log("token", token)
        try {
            const roomNo = roomNumbers[companyId];
            if (!roomNo) {
                alert('कृपया कक्ष नंबर दर्ज करें');
                return;
            }

            await axios.patch(`${BASE_SERVER_URL}/company/allotroom/${companyId}/${roomNo}`, {
                headers: { token: `${token}` }
            });
            alert('कक्ष नंबर सफलतापूर्वक आवंटित किया गया');
            // Optionally refresh the companies list to show updated room numbers
            const response = await axios.get(`${BASE_SERVER_URL}/fetchCompanies`);
            setCompanies(response.data.result.filter(candidate => candidate.role !== 'admin'));
            // Clear the input field after successful allotment
            setRoomNumbers((prev) => ({
                ...prev,
                [companyId]: '',
            }));
        } catch (error) {
            console.error("Error allotting room number:", error);
            alert('कक्ष नंबर आवंटित करने में त्रुटि');
        }
    };

    return (
        <div className="container-fluid">
            <div className="row">
                {/* Sidebar */}
                <Sidebar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />


                {/* Main Content */}
                <main className={`content ${isSidebarOpen ? "col-md-10" : "col-md-11"} p-4 ml-auto`} style={{ transition: "margin-left 0.3s" }}>
                    <h1 className="mb-4">कंपनियों के लिए कक्ष नंबर आवंटित करें</h1>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>क्रं.</th>
                                <th>कंपनी का नाम</th>
                                <th>स्थान</th>
                                <th>कक्ष नंबर</th>
                                <th>क्रिया</th>
                            </tr>
                        </thead>
                        <tbody>
                            {companies.length > 0 ? (
                                companies.map((company, index) => (
                                    <tr key={company._id}>
                                        <td data-label="क्रं.">{index + 1}</td>
                                        <td data-label="कंपनी का नाम">{company.companyName}</td>
                                        <td data-label="स्थान">{company.location}</td>
                                        <td data-label="कक्ष नंबर">{company.roomNo || 'N/A'}</td>
                                        <td data-label="">
                                            <input
                                                type="text"
                                                placeholder="कक्ष नंबर"
                                                value={roomNumbers[company._id] || ''}
                                                onChange={(e) => handleRoomNumberChange(company._id, e.target.value)}
                                            />
                                            <button
                                                className="btn btn-sm btn-primary ms-2"
                                                onClick={() => allotRoomNumber(company._id)}
                                            >
                                                आवंटित करें
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5" className="text-center">कोई रिकॉर्ड नहीं मिला</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </main>
            </div>
        </div>
    );
};

export default AllotNo;
