import React, { useEffect, useState } from 'react';
import './style/Header.css';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function Header() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        setIsLoggedIn(!!token);
    }, []);

    const handleLogout = async () => {
        const result = await Swal.fire({
            title: 'क्या आप सुनिश्चित हैं?',
            text: "क्या आप लॉग आउट करना चाहते हैं?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'हाँ, लॉग आउट करें',
            cancelButtonText: 'नहीं, रद्द करें'
        });

        if (result.isConfirmed) {
            // Remove the authToken from localStorage
            localStorage.removeItem('token');
            setIsLoggedIn(false);
            // Redirect to login page
            navigate('/login');
        }
    };
    const handleLinkClick = () => {
        // Remove the token from local storage
        localStorage.removeItem('token');

        // Optionally, you can navigate to a different route
        navigate('/'); // This is optional if you want to force a redirect
    };

    return (
        <header className="header_section1">
            <div className="header_top1">
                <div className="container-fluid1">
                    <div className="contact_nav1">
                        <Link to="/" style={{ textDecoration: 'none' }} onClick={handleLinkClick}>
                            <i className="fa fa-phone" aria-hidden="true"></i>
                            <span className="header-span">रोजगार मेला 2024</span>
                        </Link>
                        <Link to="mailto:hospital@gmail.com" style={{ textDecoration: 'none' }}>
                            {/* Add email icon or other content if needed */}
                        </Link>
                        {isLoggedIn ? (
                            <button onClick={handleLogout} style={{ background: 'none', border: 'none', color: 'inherit', cursor: 'pointer' }}>
                                <i className="fa fa-sign-out" aria-hidden="true"></i>
                                <span className="header-span">लॉगआउट</span>
                            </button>
                        ) : (
                            <Link to="/login" style={{ textDecoration: 'none' }}>
                                <i className="fa fa-envelope1" aria-hidden="true"></i>
                                <span className="header-span">लॉगिन</span>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
