import { useEffect, useState } from "react";
import axios from 'axios';
import Swal from "sweetalert2";
import Header from "./componets/Header";
import { useNavigate } from "react-router-dom";
import { BASE_SERVER_URL } from "../utilis/EndPoints";
import img from "../image/WhatsApp Image 2024-08-08 at 11.48.11 AM.jpeg";

const Home = () => {
    const [isLoading, setIsLoading] = useState(false);


    const [companies, setCompanies] = useState([]);
    const navigate = useNavigate();
    const [filteredCompanies, setFilteredCompanies] = useState([]);
    const [selectedInterest, setSelectedInterest] = useState('');
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [mobileNumber, setMobileNumber] = useState('');
    // const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        name: '',
        fathersName: '',
        dob: '',
        gender: '',
        qualification: '',
    });

    const [selectedQualifications, setSelectedQualifications] = useState([]);

    const handleQualificationChange = (event) => {
        const { value, checked } = event.target;
        const updatedQualifications = checked
            ? [...selectedQualifications, value]
            : selectedQualifications.filter(qualification => qualification !== value);

        setSelectedQualifications(updatedQualifications);
    };

    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const response = await axios.get(`${BASE_SERVER_URL}/fetchCompanies`);

                setCompanies(response.data.result);
            } catch (error) {
                console.error('Error fetching companies:', error);
            }
        };
        fetchCompanies();
    }, []);



    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = "नाम आवश्यक है।";
        if (!formData.fathersName) newErrors.fathersName = "पिता का नाम आवश्यक है।";
        if (!formData.dob) newErrors.dob = "जन्म तिथि आवश्यक है।";
        if (!formData.gender) newErrors.gender = "लिंग आवश्यक है।";
        if (!mobileNumber) newErrors.mobileNumber = "मोबाइल नंबर आवश्यक है।";
        if (selectedQualifications.length === 0) newErrors.qualification = "योग्यता आवश्यक है।";
        if (!selectedInterest) newErrors.interest = "रुचि आवश्यक है।";
        if (selectedCompanies.length === 0) newErrors.companies = "कम से कम एक कंपनी का चयन करना आवश्यक है।";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (validateForm()) {
            const data = {
                name: document.getElementById('name').value,
                fatherName: document.getElementById('fathersName').value,
                dob: document.getElementById('dob').value,
                gender: document.querySelector('input[name="gender"]:checked')?.value,
                phone: mobileNumber,
                // email: email,
                // password: password,
                qualification: selectedQualifications.join(', '),
                interest: selectedInterest,
                selectedCompanies: selectedCompanies,
            };

            console.log('Form Data:', data);

            try {
                setIsLoading(true);

                const response = await axios.post(`${BASE_SERVER_URL}/candiatesignup`, data);
                console.log('Response Data:', response.data);

                // Store token in localStorage
                if (response.data.token) {
                    localStorage.setItem('token', response.data.token);
                }

                // Call SMS API
                // await sendSMS(mobileNumber, `आपका पंजीकरण सफल हुआ है! आपका पंजीकरण आईडी है: ${response.data.result.registrationId}. इसके अलावा, आप यहाँ ${`https://frontedgeenterprises.com/rojgarmela2024/`} पर जाकर अपने मोबाइल नंबर और पासवर्ड (${response.data.result.password}) का उपयोग करके लॉगिन कर सकते हैं और अपने परिणाम देख सकते हैं।`);

                // Show success message and navigate
                Swal.fire({
                    title: 'Success!',
                    text: 'Candidate created successfully',
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then(() => {
                    // Navigate to /userdash
                    navigate('/userdash');
                });
            } catch (error) {
                console.error('Error creating candidate:', error.response.data);

                // Show error message
                Swal.fire({
                    title: '',
                    text: error.response.data.message || 'An error occurred. Please try again.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            } finally {
                setIsLoading(false);
            }
        }
    };



    // Function to call SMS API
    const sendSMS = async (phone, message) => {
        try {
            const smsResponse = await axios.post(`${BASE_SERVER_URL}/send-sms`, {
                phone,
                message,
            });
            console.log('SMS Response:', smsResponse.data);
        } catch (smsError) {
            console.error('Error sending SMS:', smsError.response?.data || smsError.message);
            // Show SMS error message
            Swal.fire({
                title: '',
                text: 'Error sending SMS. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };


    const handleInterestChange = (event) => {
        const interest = event.target.value;
        setSelectedInterest(interest);
        const filtered = companies.filter(company => company.type === interest);
        setFilteredCompanies(filtered);
    };

    const handleCompanyChange = (event) => {
        const { value, checked } = event.target;
        const updatedSelection = checked
            ? [...selectedCompanies, value]
            : selectedCompanies.filter(company => company !== value);

        if (updatedSelection.length <= 5) {
            setSelectedCompanies(updatedSelection);
        }
    };

    const handleRemoveCompany = (companyId) => {
        setSelectedCompanies(selectedCompanies.filter(company => company !== companyId));
    };

    const handleMobileNumberChange = (event) => {
        const value = event.target.value;
        if (value.length <= 10) {
            setMobileNumber(value);
        }
    };

    // const handleEmailChange = (event) => {
    //     setEmail(event.target.value);
    // };

    // const handlePasswordChange = (event) => {
    //     setPassword(event.target.value);
    // };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const getCompanyDetails = (companyId) => {
        const company = companies.find(comp => comp._id === companyId);
        return company || {};
    };

    return (
        <>
            <Header />
            <div className="container mt-5" style={{ backgroundColor: 'aliceblue' }}>
                <img src={img} alt="" className="w-100" style={{ height: "60vh" }} />
                <div className="text-center mb-3">
                    <h2 style={{ borderBottom: '1px solid' }}>रोजगार मेला पंजीकरण फॉर्म</h2>
                </div>
                <div className="alert alert-info">
                    <p>
                        यह पीडीएफ निर्देशों के बारे में है जो आपको रोजगार मेले में भाग लेने के लिए आवश्यक जानकारी प्रदान करती है।
                        इसमें आवेदन प्रक्रिया, आवश्यक दस्तावेज़, और अन्य महत्वपूर्ण दिशा-निर्देश शामिल हैं। कृपया इसे ध्यान से पढ़ें।
                    </p>
                    <a href={`${BASE_SERVER_URL}/dummypdf.pdf`} download>Download PDF</a>

                </div>



                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name" className="form-label">नाम</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            placeholder="अपना नाम दर्ज करें"
                            required
                        />
                        {errors.name && <div className="text-danger">{errors.name}</div>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="fathersName" className="form-label">पिता का नाम</label>
                        <input
                            type="text"
                            className="form-control"
                            id="fathersName"
                            name="fathersName"
                            value={formData.fathersName}
                            onChange={handleInputChange}
                            placeholder="अपने पिता का नाम दर्ज करें"
                            required
                        />
                        {errors.fathersName && <div className="text-danger">{errors.fathersName}</div>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="dob" className="form-label">जन्म तिथि</label>
                        <input
                            type="date"
                            className="form-control"
                            id="dob"
                            name="dob"
                            value={formData.dob}
                            onChange={handleInputChange}
                            required
                        />
                        {errors.dob && <div className="text-danger">{errors.dob}</div>}
                    </div>
                    <div className="form-group">
                        <label className="form-label">लिंग</label>
                        <div>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="gender"
                                    id="male"
                                    value="male"
                                    checked={formData.gender === 'male'}
                                    onChange={handleInputChange}
                                />
                                <label className="form-check-label" htmlFor="male">पुरुष</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="gender"
                                    id="female"
                                    value="female"
                                    checked={formData.gender === 'female'}
                                    onChange={handleInputChange}
                                />
                                <label className="form-check-label" htmlFor="female">महिला</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="gender"
                                    id="other"
                                    value="other"
                                    checked={formData.gender === 'other'}
                                    onChange={handleInputChange}
                                />
                                <label className="form-check-label" htmlFor="other">अन्य</label>
                            </div>
                        </div>
                        {errors.gender && <div className="text-danger">{errors.gender}</div>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="mobile" className="form-label">मोबाइल नंबर</label>
                        <input
                            type="number"
                            className="form-control"
                            id="mobile"
                            placeholder="अपना मोबाइल नंबर दर्ज करें"
                            value={mobileNumber}
                            onChange={handleMobileNumberChange}
                            required
                        />
                        {errors.mobileNumber && <div className="text-danger">{errors.mobileNumber}</div>}
                    </div>
                    {/* <p>सिस्टम में लॉगिन करने और भविष्य में अपने परिणाम देखने के लिए कृपया अपना ईमेल और पासवर्ड दर्ज करें।</p>
                    <div className="form-group mt-3">
                        <div className="form-group">
                            <label htmlFor="email" className="form-label">ईमेल</label>
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="अपना ईमेल दर्ज करें"
                                value={email}
                                onChange={handleEmailChange}
                                required
                            />
                            {errors.email && <div className="text-danger">{errors.email}</div>}
                        </div>


                    </div>

                    <div className="form-group">
                        <label htmlFor="password" className="form-label">पासवर्ड</label>
                        <input
                            type="password"
                            className="form-control"
                            id="password"
                            placeholder="अपना पासवर्ड दर्ज करें"
                            value={password}
                            onChange={handlePasswordChange}
                            required
                        />
                        {errors.password && <div className="text-danger">{errors.password}</div>}
                    </div> */}
                    <div className="form-group">
                        <label className="form-label">शैक्षिक योग्यता</label>
                        <div id="qualifications">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="highSchool"
                                    value="हाई स्कूल"
                                    checked={selectedQualifications.includes('हाई स्कूल')}
                                    onChange={handleQualificationChange}
                                />
                                <label className="form-check-label" htmlFor="highSchool">
                                    हाई स्कूल
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="higherSecondary"
                                    value="हायर सेकेंडरी स्कूल"
                                    checked={selectedQualifications.includes('हायर सेकेंडरी स्कूल')}
                                    onChange={handleQualificationChange}
                                />
                                <label className="form-check-label" htmlFor="higherSecondary">
                                    हायर सेकेंडरी स्कूल
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="iti"
                                    value="आईटीआई"
                                    checked={selectedQualifications.includes('आईटीआई')}
                                    onChange={handleQualificationChange}
                                />
                                <label className="form-check-label" htmlFor="iti">
                                    आईटीआई
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="diploma"
                                    value="डिप्लोमा"
                                    checked={selectedQualifications.includes('डिप्लोमा')}
                                    onChange={handleQualificationChange}
                                />
                                <label className="form-check-label" htmlFor="diploma">
                                    डिप्लोमा
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="graduate"
                                    value="स्नातक"
                                    checked={selectedQualifications.includes('स्नातक')}
                                    onChange={handleQualificationChange}
                                />
                                <label className="form-check-label" htmlFor="graduate">
                                    स्नातक
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="postGraduate"
                                    value="स्नातकोत्तर"
                                    checked={selectedQualifications.includes('स्नातकोत्तर')}
                                    onChange={handleQualificationChange}
                                />
                                <label className="form-check-label" htmlFor="postGraduate">
                                    स्नातकोत्तर
                                </label>
                            </div>
                        </div>
                        {errors.qualification && <div className="text-danger">{errors.qualification}</div>}
                    </div>

                    <p><b><i>Note:-</i></b> छात्र किसी भी क्षेत्र या विभिन्न क्षेत्रों में से अधिकतम 5 कंपनियाँ ही चुन सकते हैं।</p>

                    <div className="form-group">
                        <label htmlFor="interest" className="form-label">रुचि का क्षेत्र</label>
                        <select className="form-control" id="interest" onChange={handleInterestChange} required>
                            <option value="">रुचि का क्षेत्र चुनें</option>
                            <option value="तकनीकी">तकनीकी</option>
                            <option value="मार्केटिंग">मार्केटिंग</option>
                            <option value="सिक्योरिटी">सिक्योरिटी</option>
                            <option value="सर्विस">सर्विस</option>
                            <option value="इन्श्यारेंस">इन्श्यारेंस</option>
                        </select>
                        {errors.interest && <div className="text-danger">{errors.interest}</div>}
                    </div>
                    <div id="companies" style={{ display: filteredCompanies.length ? 'block' : 'none' }}>
                        <label className="form-label">कंपनियाँ</label>
                        <div id="company-options">
                            {filteredCompanies.map((company) => (
                                <div key={company._id} className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={`company-${company._id}`}
                                        value={company._id}
                                        onChange={handleCompanyChange}
                                        checked={selectedCompanies.includes(company._id)}
                                        disabled={selectedCompanies.length >= 5 && !selectedCompanies.includes(company._id)}
                                    />
                                    <label className="form-check-label" htmlFor={`company-${company._id}`}>
                                        {company.companyName}
                                    </label>
                                </div>
                            ))}
                        </div>
                        {errors.companies && <div className="text-danger">{errors.companies}</div>}
                    </div>

                    {selectedCompanies.length > 0 && (
                        <div className="mt-4">
                            <h4>चयनित कंपनियाँ</h4>
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>कंपनी का नाम</th>
                                            <th>पद</th>
                                            <th>शैक्षिक योग्यता</th>
                                            <th>उम्र</th>
                                            <th>लिंग</th>
                                            <th>वेतन</th>
                                            <th>रिक्तियाँ</th>
                                            <th>स्थान</th>
                                            <th>कार्य</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedCompanies.map((companyId) => {
                                            const company = getCompanyDetails(companyId);
                                            return (
                                                <tr key={companyId}>
                                                    <td>{company.companyName || 'Unknown Company'}</td>
                                                    <td>{company.position || '-'}</td>
                                                    <td>{company.qualification || '-'}</td>
                                                    <td>{company.age || '-'}</td>
                                                    <td>{company.gender || '-'}</td>
                                                    <td>{company.salary || '-'}</td>
                                                    <td>{company.vacancies || '-'}</td>
                                                    <td>{company.location || '-'}</td>
                                                    <td>
                                                        <button
                                                            className="btn btn-danger btn-sm"
                                                            onClick={() => handleRemoveCompany(companyId)}
                                                        >
                                                            हटाएँ
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}

                    <div className="text-center mt-3 mb-3">
                        {isLoading ? (
                            <div className="custom-loader"></div>
                        ) : (
                            <button type="submit" className="btn btn-sm btn-primary" style={{ width: '80%' }}><b>सबमिट करें</b></button>
                        )}
                    </div>
                </form>
            </div>
        </>
    );
};

export default Home;
