import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from './Sidebar';
import { BASE_SERVER_URL } from "../../utilis/EndPoints";
import * as XLSX from 'xlsx';
import ReactPaginate from 'react-paginate';
import "../style/Companies.css";

const Candidates = () => {
    const [candidates, setCandidates] = useState([]);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(0); // ReactPaginate is 0-based
    const [totalPages, setTotalPages] = useState(0);
    const [itemsPerPage] = useState(10); // Number of items per page

    useEffect(() => {
        const fetchCandidates = async () => {
            try {
                const token = localStorage.getItem("token");
                const response = await axios.get(`${BASE_SERVER_URL}/allcandidate`, {
                    headers: { token: `${token}` },
                    params: { page: currentPage + 1 } // Adjusted for the 1-based page index on the server
                });
                setCandidates(response.data.result);
                setTotalPages(response.data.totalPages);
            } catch (error) {
                console.error("Error fetching candidates:", error);
            }
        };

        fetchCandidates();
    }, [currentPage]); // Re-fetch when currentPage changes

    const handleSidebarToggle = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const extractDataForDownload = () => {
        return candidates.map((candidate, index) => ({
            'क्रं': index + 1 + currentPage * itemsPerPage,
            'नाम': candidate.name,
            'ईमेल': candidate.email,
            'पिता का नाम': candidate.fatherName,
            'जन्म तिथि': new Date(candidate.dob).toLocaleDateString(),
            'लिंग': candidate.gender,
            'फोन': candidate.phone,
            'योग्यता': candidate.qualification ? candidate.qualification.join(', ') : 'N/A',
            'रुचि': candidate.interest,
            'कंपनियाँ': candidate.companies && candidate.companies.length > 0
                ? candidate.companies.map(company => company.companyId.companyName).join(', ')
                : 'कोई रिकॉर्ड नहीं मिला',
        }));
    };

    const downloadExcel = () => {
        const data = extractDataForDownload();
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Candidates");
        XLSX.writeFile(workbook, "candidates_data.xlsx");
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    // Filtering candidates based on search term
    const filteredCandidates = candidates.filter((candidate) =>
        candidate.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    return (
        <div className="container-fluid">
            <div className="row">
                {/* Sidebar */}
                <Sidebar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />

                {/* Main Content */}
                <main className={`content ${isSidebarOpen ? "col-md-10" : "col-md-11"} p-4 ml-auto`} style={{ transition: "margin-left 0.3s" }}>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <h1 className="mb-4">उम्मीदवार</h1>
                        <button className="btn btn-sm btn-success fw-bold" onClick={downloadExcel}>एक्सेल डाउनलोड करें</button>
                    </div>

                    <div className="mb-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="नाम से खोजें..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                    </div>

                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>क्रं.</th>
                                <th>नाम</th>
                                <th>पंजीकरण</th>
                                <th>पिता का नाम</th>
                                <th>जन्म तिथि</th>
                                <th>लिंग</th>
                                <th>फोन</th>
                                <th>योग्यता</th>
                                <th>रुचि</th>
                                <th>कंपनियाँ</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredCandidates.length > 0 ? (
                                filteredCandidates.map((candidate, index) => (
                                    <tr key={candidate._id}>
                                        <td data-label="क्रं.">{index + 1 + currentPage * itemsPerPage}</td>
                                        <td data-label="नाम">{candidate.name}</td>
                                        <td data-label="पंजीकरण">{candidate.registrationId}</td>
                                        <td data-label="पिता का नाम">{candidate.fatherName}</td>
                                        <td data-label="जन्म तिथि">{new Date(candidate.dob).toLocaleDateString()}</td>
                                        <td data-label="लिंग">{candidate.gender}</td>
                                        <td data-label="फोन">{candidate.phone}</td>
                                        <td data-label="योग्यता">{candidate.qualification ? candidate.qualification.join(', ') : 'N/A'}</td>
                                        <td data-label="रुचि">{candidate.interest}</td>
                                        <td data-label="कंपनियाँ">
                                            {candidate.companies && candidate.companies.length > 0
                                                ? (
                                                    <ul className="list-unstyled">
                                                        {candidate.companies.map((company, index) => (
                                                            <li key={company._id}>
                                                                {company.companyId.companyName}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )
                                                : 'कोई रिकॉर्ड नहीं मिला'
                                            }
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="10" className="text-center">कोई रिकॉर्ड नहीं मिला</td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                    {/* Pagination Controls */}
                    <div className="d-flex justify-content-center mt-4">
                        <ReactPaginate
                            previousLabel={'< Previous'}
                            nextLabel={'Next >'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={totalPages} // total pages from the API response
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            breakLinkClassName={'page-link'}
                        />
                    </div>
                </main>
            </div>
        </div>
    );
};

export default Candidates;
