import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './UserDash.css'; // Import custom CSS
import Header from '../componets/Header';
import { Link, useNavigate } from 'react-router-dom';
import { BASE_SERVER_URL } from "../../utilis/EndPoints";

const Information = () => {
  const [candidate, setCandidate] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCandidateData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${BASE_SERVER_URL}/candidate`, {
          headers: {
            token: `${token}`
          }
        });
        console.log(response.data.result);
        setCandidate(response.data.result);
      } catch (error) {
        console.error('Error fetching candidate data:', error);
      }
    };

    fetchCandidateData();
  }, []);

  return (
    <>
      <Header />
      <div className="container user-dash-container">
        <p ><b><i>Note:</i></b> <Link to='/userdash'>जनरेट किए गए पाउच देखने के लिए यहाँ क्लिक करें।</Link></p>
        {candidate ? (
          <div className="card candidate-card">
            <div className="card-body">
              <div className="row">
                {/* <div className="col-md-4">
                  <div className="card candidate-info-card">
                    <div className="card-header">उम्मीदवार की जानकारी</div>
                    <div className="card-body">
                      <h3 className="candidate-name">{candidate.name}</h3>
                      <h5 className="candidate-email text-dark fw-bold">पंजीकरण आईडी: {candidate.registrationId}</h5>
                      <ul className="candidate-details">
                        <li className='mb-2'><strong>पिता का नाम:</strong> {candidate.fatherName}</li>
                        <li className='mb-2'><strong>जन्म तिथि:</strong> {new Date(candidate.dob).toLocaleDateString()}</li>
                        <li className='mb-2'><strong>लिंग:</strong> {candidate.gender}</li>
                        <li className='mb-2'><strong>फोन:</strong> {candidate.phone}</li>
                        <li className='mb-2'><strong>योग्यता:</strong> {candidate.qualification.join(', ')}</li>
                        <li className='mb-2'><strong>रुचि:</strong> {candidate.interest}</li>
                      </ul>
                    </div>
                  </div>

                </div> */}
                {/* <div className="col-8">
                  <div className="card companies-card">
                    <div className="card-header">कंपनियाँ</div>
                    <div className="card-body">
                      {candidate.companies.length > 0 ? (
                        <>
                          <div className="table-responsive">
                            <table className="table table-striped table-hover company-table">
                              <thead>
                                <tr>
                                  <th>क्र. कंपनी का नाम</th>
                                  <th>कक्ष नंबर</th>
                                </tr>
                              </thead>
                              <tbody>
                                {candidate.companies.map((company) => (
                                  <tr key={company._id}>
                                    <td>{company.companyId.companyName}</td>
                                    <td>{company.companyId.roomNo}</td>

                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>



                        </>
                      ) : (
                        <p>कोई कंपनियाँ उपलब्ध नहीं हैं।</p>
                      )}
                    </div>
                  </div>
                </div> */}
                <div className="col-md-12 m-auto mt-3">
                  <div className="col-md-12 mb-3">
                    <div className="card additional-info-card">
                      <div className="card-header">अतिरिक्त जानकारी</div>
                      <div className="card-body additional-info-scroll">
                        <div className="table-responsive">
                          <table className="table table-striped table-hover">
                            <thead>
                              <tr>
                                <th>क्र. कंपनी का नाम</th>
                                <th>उपस्थिति</th>
                                <th>परिणाम</th>
                                {/* <th>टिप्पणी</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {candidate.companies.map((company) => (
                                <tr key={company._id}>
                                  <td>{company.companyId.companyName}</td>
                                  <td className={`fw-bold ${company.present ? 'text-success' : 'text-danger'}`}>{company.present ? 'उपस्थित' : 'अनुपस्थित'}</td>
                                  <td style={{ color: company.result === 'selected' ? 'green' : company.result === 'rejected' ? 'red' : 'black', fontWeight: '500' }}>
                                    {company.result === 'selected' ? 'चयनित' : company.result === 'rejected' ? 'अस्वीकृत' : company.result || 'N/A'}
                                  </td>
                                  {/* <td>{company.remark}</td> */}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        ) : (
          <div className="loading">उम्मीदवार की जानकारी लोड हो रही है...</div>
        )}
      </div >
    </>
  );
};

export default Information;
