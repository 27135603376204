import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Header from '../componets/Header'; // Fixed typo
import { BASE_SERVER_URL } from '../../utilis/EndPoints'; // Fixed typo
import * as XLSX from 'xlsx';
import ReactPaginate from 'react-paginate'; // Importing the pagination library

const CompanyDash = () => {
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submitError, setSubmitError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0); // State for current page
  const [itemsPerPage] = useState(10); // Number of items per page
  const navigate = useNavigate();

  useEffect(() => {
    fetchCandidates();
  }, []);

  const fetchCandidates = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${BASE_SERVER_URL}/fetchCandidatesByCompany`, {
        headers: { token: `${token}` }
      });
      console.log('Fetched candidates:', response.data.result);
      setCandidates(response.data.result);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const handleExportToExcel = () => {
    const filteredCandidates = candidates
      .filter(item =>
        item.companies[0].present && (
          item.registrationId?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.name?.toLowerCase().includes(searchTerm.toLowerCase())
        )
      )
      .map(item => ({
        'Name': item.name || 'N/A',
        'Registration ID': item.registrationId || 'N/A',
        'Attendance': item.companies[0].present ? 'Present' : 'Absent',
        'Result': item.companies[0].result || 'N/A',
        'Remark': item.companies[0].remark || 'N/A',
      }));

    const worksheet = XLSX.utils.json_to_sheet(filteredCandidates);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Present Candidates");
    XLSX.writeFile(workbook, "present_candidates.xlsx");
  };

  const handleAttendance = async (candidateId, isPresent) => {
    try {
      const token = localStorage.getItem("token");
      const attendanceData = {
        candidateId,
        present: !isPresent,
      };
      const response = await axios.patch(`${BASE_SERVER_URL}/company/attendees`, attendanceData, {
        headers: { token: `${token}` }
      });
      console.log('Attendance update response:', response);
      setSubmitSuccess(response.data.message);
      setSubmitError(null);
      fetchCandidates();
    } catch (err) {
      setSubmitError(err.response?.data?.error || err.message);
      setSubmitSuccess(null);
    }
  };

  const filteredCandidates = candidates.filter(candidate =>
    candidate.registrationId.toLowerCase().includes(searchTerm.toLowerCase().trim()) ||
    candidate.name.toLowerCase().includes(searchTerm.toLowerCase().trim())
  );

  // Pagination logic
  const indexOfLastCandidate = (currentPage + 1) * itemsPerPage;
  const indexOfFirstCandidate = indexOfLastCandidate - itemsPerPage;
  const currentCandidates = filteredCandidates.slice(indexOfFirstCandidate, indexOfLastCandidate);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  if (loading) {
    return <div className="text-center">लोड हो रहा है...</div>;
  }

  if (error) {
    return <div className="text-center text-danger">त्रुटि: {error}</div>;
  }

  return (
    <>
      <Header />
      <div className="container mt-5">
        <h2 className="text-center mb-4">उम्मीदवार सूची</h2>
        <div className="d-flex gap-5">

          <button
            className="btn btn-secondary w-100 w-md-25"
            onClick={() => navigate('/companydash2')}
          >
            परिणाम दर्ज करें
          </button>
          <input
            type="text"
            className="form-control"
            placeholder="नाम या पंजीकरण आईडी द्वारा खोजें"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            className="btn btn-secondary w-100 w-md-25"
            onClick={handleExportToExcel}
          >
            डाउनलोड Excel
          </button>
        </div>

        {/* Feedback Messages */}
        {submitSuccess && <div className="text-success mt-3">{submitSuccess}</div>}
        {submitError && <div className="text-danger mt-3">त्रुटि: {submitError}</div>}

        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>पंजीकरण आईडी</th>
                <th>नाम</th>
                <th>पिता का नाम</th>
                <th>लिंग</th>
                <th>फोन</th>
                <th>योग्यता</th>
                <th>उपस्थिति</th>
                <th>क्रियाएं</th>
              </tr>
            </thead>
            <tbody>
              {currentCandidates.map(candidate => (
                <tr key={candidate._id}>
                  <td>{candidate.registrationId}</td>
                  <td>{candidate.name}</td>
                  <td>{candidate.fatherName}</td>
                  <td>{candidate.gender}</td>
                  <td>{candidate.phone}</td>
                  <td>{candidate.qualification.join(', ')}</td>
                  <td className={`fw-bold ${candidate.companies[0].present ? 'text-success' : 'text-danger'}`}>
                    {candidate.companies[0].present ? 'उपस्थित' : 'अनुपस्थित'}</td>
                  <td>
                    <button
                      className={`btn ${candidate.companies[0].present ? 'btn-danger' : 'btn-primary'} mb-2`}
                      onClick={() => handleAttendance(candidate._id, candidate.companies[0].present)}
                    >
                      {candidate.companies[0].present ? 'अनुपस्थित चिह्नित करें' : 'उपस्थित चिह्नित करें'}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination Component */}
        <div className="d-flex justify-content-center mt-4">
          <ReactPaginate
            previousLabel={'< Previous'}
            nextLabel={'Next >'}
            breakLabel={'...'}
            pageCount={Math.ceil(filteredCandidates.length / itemsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            activeClassName={'active'}
          />
        </div>
      </div>
    </>
  );
};

export default CompanyDash;
