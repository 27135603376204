import React, { useState, useEffect } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import "bootstrap/dist/css/bootstrap.min.css";
import Sidebar from "./Sidebar";
import { BASE_SERVER_URL } from "../../utilis/EndPoints";
import ReactPaginate from "react-paginate"; // Importing the pagination library
import "../style/Companies.css";

const Companies = () => {
  const [companies, setCompanies] = useState([]);
  const [currentPage, setCurrentPage] = useState(0); // State for current page
  const [companiesPerPage] = useState(10); // Number of companies per page
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    // Fetch the list of companies
    const fetchCompanies = async () => {
      try {
        const response = await axios.get(`${BASE_SERVER_URL}/fetchCompanies`);
        const filteredCompanies = response.data.result.filter(
          (candidate) => candidate.role !== "admin"
        );
        setCompanies(filteredCompanies);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  const extractDataForDownload = () => {
    return companies.map((company, index) => ({
      क्रं: index + 1,
      "कंपनी का नाम": company.companyName,
      पद: company.position,
      योग्यता: company.qualification,
      उम्र: company.age,
      लिंग: company.gender,
      वेतन: company.salary,
      रिक्तियां: company.vacancies,
      स्थान: company.location,
      "कक्ष नंबर": company.roomNo,
    }));
  };

  const downloadExcel = () => {
    const data = extractDataForDownload();
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Companies");
    XLSX.writeFile(workbook, "companies_data.xlsx");
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredCompanies = companies.filter((company) =>
    company.companyName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination logic
  const indexOfLastCompany = (currentPage + 1) * companiesPerPage;
  const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;
  const currentCompanies = filteredCompanies.slice(
    indexOfFirstCompany,
    indexOfLastCompany
  );

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        {/* Sidebar */}
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />

        {/* Main Content */}
        <main
          className={`content ${isSidebarOpen ? "col-md-10" : "col-md-11"} p-4 ml-auto`}
          style={{ transition: "margin-left 0.3s" }}
        >
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h1>कंपनियाँ</h1>
            <button className="btn btn-sm btn-success fw-bold" onClick={downloadExcel}>
              एक्सेल डाउनलोड करें
            </button>
          </div>

          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="कंपनी का नाम खोजें..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>

          <table className="table table-bordered">
            <thead>
              <tr>
                <th>क्रं.</th>
                <th>कंपनी का नाम</th>
                <th>पद</th>
                <th>योग्यता</th>
                <th>उम्र</th>
                <th>लिंग</th>
                <th>वेतन</th>
                <th>रिक्तियां</th>
                <th>स्थान</th>
                <th>कक्ष नंबर</th>
              </tr>
            </thead>
            <tbody>
              {currentCompanies.length > 0 ? (
                currentCompanies.map((company, index) => (
                  <tr key={company._id}>
                    <td data-label="क्रं">{indexOfFirstCompany + index + 1}</td>
                    <td data-label="कंपनी का नाम">{company.companyName}</td>
                    <td data-label="पद">{company.position}</td>
                    <td data-label="योग्यता">{company.qualification}</td>
                    <td data-label="उम्र">{company.age}</td>
                    <td data-label="लिंग">{company.gender}</td>
                    <td data-label="वेतन">{company.salary}</td>
                    <td data-label="रिक्तियां">{company.vacancies}</td>
                    <td data-label="स्थान">{company.location}</td>
                    <td data-label="कक्ष नंबर">{company.roomNo}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="10" className="text-center">
                    कोई रिकॉर्ड नहीं मिला
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Pagination Component */}
          <div className="d-flex justify-content-center mt-4">
            <ReactPaginate
              previousLabel={'< Previous'}
              nextLabel={'Next >'}
              breakLabel={'...'}
              pageCount={Math.ceil(filteredCompanies.length / companiesPerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              breakClassName={'page-item'}
              breakLinkClassName={'page-link'}
              activeClassName={'active'}
            />
          </div>
        </main>
      </div>
    </div>
  );
};

export default Companies;