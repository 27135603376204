import React, { useState, useEffect } from 'react';
import Header from '../componets/Header.jsx';
import axios from 'axios';
import { BASE_SERVER_URL } from "../../utilis/EndPoints";
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';

const CompanyResult = () => {
  const navigate = useNavigate();
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedCandidate, setSelectedCandidate] = useState('');
  const [remark, setRemark] = useState('');
  const [remarkError, setRemarkError] = useState(false);
  const [searchId, setSearchId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    fetchCandidates();
  }, []);

  const fetchCandidates = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${BASE_SERVER_URL}/fetchCandidatesByCompany`, {
        headers: { token: `${token}` }
      });
      setCandidates(response.data.result);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const handleStatusUpdate = (candidateId) => {
    if (!remark) {
      setRemarkError(true);
      return;
    }
    setRemarkError(false);

    const token = localStorage.getItem("token");

    fetch(`${BASE_SERVER_URL}/company/attendees/mark`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        token: `${token}`
      },
      body: JSON.stringify({
        candidateId,
        result: selectedStatus,
        remark
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.message === "Status updated successfully") {
          alert("Status updated successfully");
          setCandidates(prevData =>
            prevData.map(item =>
              item._id === candidateId ? { ...item, status: selectedStatus } : item
            )
          );
          fetchCandidates();
        } else {
          alert(data.message);
        }
      })
      .catch(error => {
        console.error('Error:', error);
        alert('Failed to update status');
      });
  };

  const handleExportToExcel = () => {
    const presentCandidates = candidates
      .filter(item => item.companies[0].present === true && item.registrationId?.toLowerCase().includes(searchId.toLowerCase()))
      .map(item => ({
        'Name': item.name || 'N/A',
        'Registration ID': item.registrationId || 'N/A',
        'Attendance': item.companies[0].present ? 'Present' : 'Absent',
        'Result': item.companies[0].result || 'N/A',
        'Remark': item.companies[0].remark || 'N/A',
      }));

    const worksheet = XLSX.utils.json_to_sheet(presentCandidates);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Present Candidates");
    XLSX.writeFile(workbook, "present_candidates.xlsx");
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const filteredCandidates = candidates.filter(item =>
    item.registrationId?.toLowerCase().includes(searchId.toLowerCase().trim())
  );

  const totalPages = Math.ceil(filteredCandidates.length / itemsPerPage);
  const currentCandidates = filteredCandidates.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <>
      <Header />
      <div className="container mt-4">
        <h2 className='text-center'>कंपनी के उपस्थित लोग</h2>

        <div className="d-flex gap-5">
          <button
            className="btn btn-sm btn-secondary w-10 w-md-25"
            onClick={() => navigate('/companydash')}
          >
            उम्मीदवार सूची देखें
          </button>

          <input
            type="text"
            className="form-control"
            placeholder="Search by Registration ID"
            value={searchId}
            onChange={(e) => setSearchId(e.target.value)}
          />

          <button
            className="btn btn-secondary w-10 w-md-25"
            onClick={handleExportToExcel}
          >
            डाउनलोड Excel
          </button>
        </div>

        {currentCandidates.length > 0 ? (
          <div className="table-responsive">
            <table className="table table-bordered table-striped">
              <thead className="thead-dark">
                <tr>
                  <th>उम्मीदवार का नाम</th>
                  <th>पंजीकरण आईडी</th>
                  <th>उपस्थिति</th>
                  <th>परिणाम</th>
                  <th>टिप्पणी</th>
                  <th>अद्यतन स्थिति</th>
                </tr>
              </thead>
              <tbody>
                {currentCandidates.filter(item => item.companies[0].present === true).map((item) => (
                  <tr key={item._id}>
                    <td>{item.name || 'N/A'}</td>
                    <td>{item.registrationId || 'N/A'}</td>
                    <td className={`fw-bold ${item.companies[0].present ? 'text-success' : 'text-danger'}`}>
                      {item.companies[0].present ? 'उपस्थित' : 'अनुपस्थित'}</td>
                    <td style={{ color: item.companies[0].result === 'selected' ? 'green' : item.companies[0].result === 'rejected' ? 'red' : 'black' }}>
                      {item.companies[0].result === 'selected' ? 'चयनित' : item.companies[0].result === 'rejected' ? 'अस्वीकृत' : item.companies[0].result || 'N/A'}
                    </td>
                    <td>{item.companies[0].remark}</td>
                    <td className='d-flex gap-2'>
                      <input
                        type="text"
                        className={`form-control w-25 ${remarkError ? 'is-invalid' : ''}`}
                        placeholder="टिप्पणी दर्ज करें"
                        value={selectedCandidate === item._id ? remark : ''}
                        onChange={(e) => {
                          setSelectedCandidate(item._id);
                          setRemark(e.target.value);
                          setRemarkError(false);
                        }}
                      />

                      <select
                        className="form-control w-25"
                        value={selectedCandidate === item._id ? selectedStatus : ''}
                        onChange={(e) => {
                          setSelectedCandidate(item._id);
                          setSelectedStatus(e.target.value);
                          setRemarkError(false);
                        }}
                      >
                        <option value="">स्थिति चुनें</option>
                        <option value="selected">चयनित</option>
                        <option value="rejected">अस्वीकार कर दिया</option>
                      </select>

                      <button
                        className="btn btn-primary"
                        onClick={() => handleStatusUpdate(item._id)}
                        disabled={!selectedStatus}
                      >
                        अद्यतन स्थिति
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div>No attendees found.</div>
        )}

        {/* Pagination Controls */}
        <div className="d-flex justify-content-between mt-4">
          <button
            className="btn btn-secondary"
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span>Page {currentPage} of {totalPages}</span>
          <button
            className="btn btn-secondary"
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default CompanyResult;