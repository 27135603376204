// src/UserProfile.js

import React,{useState} from "react";
// import "../css/Profile.css";
import "../css/Dashboard.css";
import { Link, useNavigate } from "react-router-dom";
import Slider from "./slider"
import swal from "sweetalert";
import { FaRegEdit } from "react-icons/fa";
import { TbSubtask } from "react-icons/tb";
import Header from "./componets/Header";
const UserProfile = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };
  // Mock user data
    const navigate = useNavigate();
    // Mock user data
    const handleLogout = () => {
      // Remove token from localStorage
 // <-- Corrected
      // Redirect to home page
     
      swal({
        title: "Are you sure?",
        text: "Are you sure that you want to logout?",
        icon: "warning",
        buttons:true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          swal("Logout!", "You are logout successfully", "success");
               localStorage.removeItem("authToken"); 
           navigate("/");

        }
      });
    };


  return (
    <>
      <div class="dashboard">
        {/* <Header /> */}
        <Slider onToggleSidebar={handleSidebarToggle} />
        <main class="content-wrap">
          <div class="content">
            user
          </div>
        </main>
      </div>
    </>
  );
};

export default UserProfile;
