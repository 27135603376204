import { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "../componets/Header"; // Corrected the path
import { BASE_SERVER_URL } from "../../utilis/EndPoints";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

const CompanyLogin = () => {
  const navigate = useNavigate();
  const [companyId, setCompanyId] = useState("");
  const [companyPassword, setCompanyPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${BASE_SERVER_URL}/companylogin`, {
        username: companyId,
        password: companyPassword,
      });

      console.log(response.data.result._id
      );

      Swal.fire("सफलता", "लॉगिन सफल रहा!", "success");
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("companyID", response.data._id);
      setIsLoggedIn(true);

      // Check the user's role and navigate accordingly
      const userRole = response.data.result.role;
      if (userRole === "admin") {
        navigate("/admindash");
      } else {
        navigate("/companydash");
      }
    } catch (error) {
      if (error.response) {
        setErrorMessage(error.response.data.message || "Login failed. Please try again.");
        Swal.fire("Error", error.response.data.error || error.response.data.message, "error");
      } else {
        setErrorMessage("Login failed. Please try again.");
        Swal.fire("Error", "Login failed. Please try again.", "error");
      }
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("token");
    Swal.fire("Success", "Logged out successfully!", "success");
    navigate("/");
  };
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <>
      <Header />
      <div className="container mt-5">
        {isLoggedIn ? (
          <div className="text-center">
            <h2>You are logged in!</h2>
            <button className="btn btn-primary mt-3" onClick={handleLogout}>
              Logout
            </button>
          </div>
        ) : (
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="card p-4">
                <h2 className="card-title text-center mb-4">कंपनी लॉगिन</h2>
                <form onSubmit={handleLogin}>
                  <div className="mb-3">
                    <label className="form-label">ईमेल/Username:</label> {/* Changed label to "Username" */}
                    <input
                      type="text"
                      className="form-control"
                      value={companyId}
                      onChange={(e) => setCompanyId(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3 position-relative">
                    <label className="form-label">पासवर्ड/Password:</label>
                    <input
                      type={passwordVisible ? "text" : "password"}
                      className="form-control"
                      value={companyPassword}
                      onChange={(e) => setCompanyPassword(e.target.value)}
                      required
                    />
                    <button
                      type="button"
                      className="btn  position-absolute top-50 end-0 border-none "
                      style={{ right: "10px", marginTop: "-3px" }}
                      onClick={togglePasswordVisibility}
                    >
                      {passwordVisible ? <FaRegEye /> : <FaRegEyeSlash />}
                    </button>
                  </div>
                  {errorMessage && (
                    <div className="alert alert-danger" role="alert">
                      {errorMessage}
                    </div>
                  )}
                  <div className="d-grid">
                    <button type="submit" className="btn btn-primary">
                      लॉगिन
                    </button>
                  </div>
                </form>
                <div className="text-center mt-3">
                  <Link to="/login">क्या आप एक उम्मीदवार हैं? कृपया यहाँ लॉगिन करें।</Link> {/* Updated the link text */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CompanyLogin;
